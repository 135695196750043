<template>
  <VTextField
      :label="label"
      :rules="rules"
      @input="(value) => $emit('input', value)"
      :type="show ? 'text' : 'password'"
      :append-icon="show ? 'mdi-eye-off': 'mdi-eye'"
      @click:append="show = !show"
      outlined
      dense
      hideDetails="auto"
      autocomplete="new-password" />
</template>

<script>
export default {
  name: 'PasswordField',
  props: {
    rules: { type: Array },
    label: { type: String },
  },
  data() {
    return {
      show: false,
    }
  }
}
</script>
